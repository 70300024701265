import React from "react"
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from "../components/SolutionPersonalBranding/Sd1mainBanner"
import FeaturedService from "../components/SolutionPersonalBranding/Sd2featuredService"
import AboutUs from "../components/SolutionPersonalBranding/AboutUs"
import OurMission from "../components/SolutionPersonalBranding/OurMission"
import Funfacts from "../components/SolutionPersonalBranding/Funfacts"
import OurServices from "../components/SolutionPersonalBranding/OurServices"
import HowItWork from "../components/SolutionPersonalBranding/HowItWork"
import PromotionProfilProfessionnel from "../components/SolutionPersonalBranding/Sd3promotionProfilProfessionnel"
import Testimonials from "../components/SolutionPersonalBranding/Sd4testimonials"
import Partner from "../components/SolutionPersonalBranding/Partner"
import StartProject from "../components/SolutionPersonalBranding/Sd5startProject"
import OurBlog from "../components/SolutionPersonalBranding/BlogPost"


const Home = ({location}) => {
  return (
    <Layout path={location.pathname}>
      <NavbarThree />
      <MainBanner />
      <FeaturedService />

      {/*
      <AboutUs />
      <OurMission />
      <Funfacts />
      <B1ourServices />
      <HowItWork />
      */}
      <PromotionProfilProfessionnel />
      <Testimonials />

      {/*
      <Partner />
      */}

        <StartProject />

      {/*
      <OurBlog />
      */}

        <Footer />
    </Layout>
  )
}

export default Home;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;